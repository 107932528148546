%common-section-alignment {
  width: 80%;
  margin: auto;
  padding: 2% 0;
}

%image-title-text {
  color: #070707;
  font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
  font-size: 18px;
}

.fitness {
  &-section1 {
    @extend %common-section-alignment;

    &-title {
      color: #070707;
      font-weight: 700;
      line-height: 1.1em;
      font-size: 80px;
      white-space: pre-line;
      margin-bottom: 2.75%;
    }

    &-types {
      margin-bottom: 4.758%;
      &-text {
        font-family: Open Sans, Arial, sans-serif;
        line-height: 1.8em;
        color: #666;
        font-weight: 500;
        font-size: 16px;
        color: #666;
      }
    }
  }

  &-section2 {
    @extend %common-section-alignment;

    &-title {
      font-size: 62px;
      color: #003e51;
      line-height: 1.1em;
      padding-bottom: 10px;
      font-weight: 700;
      white-space: pre-line;
      text-align: center;
    }

    &-each-fitness {
      margin-right: 5.5%;
      width: 29.6667%;
      text-align: center;
      float: left;
      padding-top: 70px;
      padding-bottom: 50px;

      &-title {
        @extend %image-title-text;
        font-weight: 700;
        padding-bottom: 1em;
        white-space: pre-line;
        margin-top: 30px;
      }

      &:last-child {
        margin-right: 0px !important;
      }
    }

    &-button-wrapper {
      display: flex;
      justify-content: center;
    }

    &-line-wrapper {
      padding: 30px 0;
      display: flex;
      justify-content: center;

      &-line {
        height: 2px;
        background-color: rgba(131, 134, 134, 0.5);
        width: 30%;
      }
    }

    &::after {
      content: "";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0;
      width: 0;
    }
  }

  &-section4 {
    @extend %common-section-alignment;

    &-title {
      margin-bottom: 2.75%;
      text-align: center;
      color: #2b4368;
      white-space: pre-line;
      font-size: 62px;
      line-height: 1.1em;
      padding-bottom: 10px;
      font-weight: 700;
    }

    &-desc {
      font-size: 16px;
      line-height: 1.8em;
      padding-bottom: 0;
      margin-bottom: 51px;
      text-align: center;
      color: #666;
    }

    &-button-wrapper {
      display: flex;
      justify-content: center;
    }
  }
}

@media (max-width: 980px) {
  .fitness {
    &-section2 {
      &-each-fitness {
        width: 100% !important;
        padding-bottom: 0px;
      }
      &-title {
        font-size: 34px;
        white-space: normal;
      }
    }
    &-section4 {
      &-title {
        font-size: 34px;
        white-space: normal;
      }
    }
  }
}
