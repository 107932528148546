.medical-certi-steps-container {
  width: 100%;
  background-color: #e5f3fc !important;
  padding-top: 30px;
  padding-bottom: 4px;
}

%step-1-2-common {
  float: left;
  width: 47.25%;
  background-color: #ffffff;
  border-radius: 30px 30px 30px 30px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3);
  transition: box-shadow 300ms ease;
}

.cert-step-1 {
  width: 150px;
  height: auto;
}

.medical-certi-steps-container-section-1 {
  width: 80%;
  margin: auto;
  padding: 30px 0;

  &-step-1 {
    &-section-1 {
      margin-bottom: 30px;
      text-align: center;
      display: flex;
      justify-content: center;
      &-text {
        font-weight: 700;
        color: #070707 !important;
        line-height: 1.1em;
        font-size: 62px;
        max-width: 800px;
      }
    }
    &-section-2 {
      display: flex;
      justify-content: center;
      &-divider {
        height: 2px;
        width: 25%;
        background-color: rgba(131, 134, 134, 0.5);
        margin-bottom: 50px;
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 980px) {
  .medical-certi-steps-container-section-1 {
    &-step-1 {
      &-section-1 {
        &-text {
          font-size: 34px;
        }
      }
    }
  }
}

.medical-certi-steps-container-section-2 {
  width: 80%;
  margin: auto;

  &-step-1 {
    &:hover {
      box-shadow: 0 50px 80px -40px rgba(48, 133, 147, 0.8);
    }
    margin-right: 5.5%;
    @extend %step-1-2-common;

    &-wrapper {
      text-align: left;
      padding-top: 64px;
      padding-right: 64px;
      padding-bottom: 32px;
      padding-left: 40px;

      &-section-1 {
        width: 32px;
        display: table-cell;
      }
      &-section-2 {
        padding-left: 15px;
        display: table-cell;
        vertical-align: top;

        &-title {
          font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
          font-weight: 700;
          font-size: 15px;
          color: #070707 !important;
          letter-spacing: 0.5px;
          line-height: 1.6em;
          padding-bottom: 10px;
        }

        &-desc {
          color: #666;
          background-color: #fff;
          line-height: 1.7em;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }

  &-step-2 {
    &:hover {
      box-shadow: 0 50px 80px -40px rgba(48, 133, 147, 0.8);
    }
    @extend %step-1-2-common;
    margin-right: 0 !important;

    &-wrapper {
      text-align: left;

      padding-top: 64px;
      padding-right: 64px;
      padding-bottom: 32px;
      padding-left: 40px;

      &-section-1 {
        width: 32px;
        display: table-cell;
      }

      &-section-2 {
        padding-left: 15px;
        display: table-cell;
        vertical-align: top;

        &-title {
          font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
          font-weight: 700;
          font-size: 15px;
          color: #070707 !important;
          letter-spacing: 0.5px;
          line-height: 1.6em;
          padding-bottom: 10px;
        }

        &-desc {
          color: #666;
          background-color: #fff;
          line-height: 1.7em;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }

  &::after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
    width: 0;
  }
}

@media (max-width: 980px) {
  .medical-certi-steps-container-section-2 {
    &-step-1,
    &-step-2 {
      &-wrapper {
        padding-top: 56px;
        padding-bottom: 32px;
        padding-left: 30px;
      }
    }

    &-step-1,
    &-step-2 {
      &-wrapper {
        &-section-1 {
          img {
            width: 60px;
            height: 60px;
          }
        }
      }
      width: 100%;
      margin-bottom: 30px;
    }
  }
}

.medical-certi-steps-container-section-3 {
  width: 80%;
  margin: auto;
  padding: 30px 0;

  &-step-1 {
    &:hover {
      box-shadow: 0 50px 80px -40px rgba(48, 133, 147, 0.8);
    }

    background-color: #ffffff;
    border-radius: 30px 30px 30px 30px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3);
    transition: box-shadow 300ms ease;
    padding-top: 56px;
    padding-bottom: 32px;
    padding-left: 30px;
    padding-right: 64px;

    &-section-1 {
      width: 32px;
      display: table-cell;
    }

    &-section-2 {
      padding-left: 15px;
      display: table-cell;
      vertical-align: top;

      &-title {
        font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
        font-weight: 700;
        font-size: 15px;
        color: #003e51 !important;
        letter-spacing: 0.5px;
        line-height: 1.6em;
        padding-bottom: 10px;
      }

      &-desc {
        color: #666;
        background-color: #fff;
        line-height: 1.7em;
        font-weight: 500;
        font-size: 14px;
      }
    }

    &-section-3 {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }
  }

  &::after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
    width: 0;
  }
}

@media (max-width: 980px) {
  .medical-certi-steps-container-section-3 {
    &-step-1 {
      &-section-1 {
        img {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}
