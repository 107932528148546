.radio-input {
  display: flex;
  flex-direction: column;

  &-label {
    font-family: Open Sans, Open Sans, sans-serif;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    text-transform: none;
    text-decoration: none;
    color: #222;
    padding-bottom: 8px;

    em {
      color: #ff0000 !important;
    }
  }

  &-options-label {
    font-family: Open Sans, Open Sans, sans-serif;
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
    text-transform: none;
    text-decoration: none;
    color: #4a4a4a;
    padding-bottom: 8px;
    padding-left: 5px;
  }

  &-form-error {
    font-family: Open Sans, Open Sans, sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #b00;
    margin-top: 4px;
  }

  &-field-wrapper {
    display: flex;
  }

  input[type="radio"] {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    border: 1px solid darkgray;
    border-radius: 50%;
    outline: none;
  }

  input[type="radio"]:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    margin: 10% auto;
    border-radius: 50%;
  }

  input[type="radio"]:checked::before {
    background: green;
  }
}
