.text-input {
  display: flex;
  flex-direction: column;

  label {
    font-family: Open Sans, Open Sans, sans-serif;
    font-size: 13px;
    line-height: 19px;
    font-weight: 500;
    text-transform: none;
    text-decoration: none;
    color: #7c7c7c;
    padding-bottom: 8px;
  }

  input {
    font-size: 16px;
    border: 1px solid #4a4a4a;
    border-radius: 4px;
    color: #070707;
    font-weight: 400;
    padding: 16px;
  }

  .form-error {
    font-family: Open Sans, Open Sans, sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #b00;
    margin-top: 4px;
  }
}
