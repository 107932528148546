.trust-container {
  width: 100%;
  background-color: #0b582b;

  &-section1 {
    background-color: #0b582b;
    width: 80%;
    margin: auto;
    padding: 2% 0;

    &-text {
      text-align: center;
      font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
      font-weight: 700;
      color: #ffffff;
      font-size: 60px;
      line-height: 1em;
    }
  }

  %trust-section-text {
    color: #ffffff;
    font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
    font-size: 18px;
  }

  &-section2 {
    background-color: #0b582b;
    &-wrapper {
      width: 80%;
      margin: auto;
      &-section {
        margin-right: 5.5%;
        width: 29.6667%;
        text-align: center;
        float: left;
        padding-top: 70px;
        padding-bottom: 50px;

        &-img-wrapper {
          margin-bottom: 30px;
        }
        &-text {
          @extend %trust-section-text;
          font-weight: 700;
          padding-bottom: 1em;
        }
        &-data {
          @extend %trust-section-text;
          font-weight: 500;
          line-height: 1.5em;
        }

        &:last-child {
          margin-right: 0px !important;
        }
      }

      &::after {
        content: "";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
        width: 0;
      }
    }
  }
}

@media (max-width: 980px) {
  .trust-container-section2 {
    &-wrapper {
      &-section {
        width: 100% !important;
        margin-bottom: 30px;
        margin-right: 0px !important;
        padding-top: 0px;
      }
    }
  }
}
