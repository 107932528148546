.faq-section {
  padding: 20px;
  border-radius: 30px 0 30px 0;
  background-color: rgba(224, 233, 249, 0.34);
  margin-bottom: 2.75%;
}

.faq-qn-icon {
  display: flex;
  justify-content: space-between;
}

%common-text {
  font-family: "Work Sans", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 1.2em;
  cursor: pointer;
}

.faq-question-text {
  @extend %common-text;
  color: #666;

  &-open {
    @extend %common-text;
    color: #fff;
  }
}

.faq-answer-text {
  font-family: "Work Sans", Helvetica, Arial, Lucida, sans-serif;
  font-size: 16px;
  color: #fff;
  line-height: 1.8em;
  font-weight: 500;
  padding-top: 20px;
}

.open {
  background-color: #5e89fb;
}

.add-icon-wrapper {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #5e89fb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq-title {
  font-family: "Work Sans", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 1.4em;
  color: #2a4368;
  margin-bottom: 2.75%;
}

.card-container {
  background-color: #fff;
  border-radius: 60px 0 60px 0;
  overflow: hidden;
  box-shadow: 40px 40px 190px 0 rgba(6, 10, 76, 0.19);
  display: grid;
  grid-template-columns: 1fr 1fr;

  width: 80%;
  max-width: 1080px;
  margin: auto;

  &-section1 {
    padding: 80px 0 100px 100px;

    &-title1 {
      font-family: "Work Sans", Helvetica, Arial, Lucida, sans-serif;
      font-weight: 700;
      font-size: 60px;
      line-height: 1.2em;
      color: #2a4368;
      padding-bottom: 10px;
    }
    &-title2 {
      font-family: "Work Sans", Helvetica, Arial, Lucida, sans-serif;
      line-height: 1.5em;
      color: #666;
      font-weight: 500;
      font-size: 20px;
    }

    &-button-wrapper {
      background-color: #62de9d;
      border-radius: 5px;
      border-width: 5px;
      letter-spacing: 2px;
      padding: 20px 25px;
      display: inline-block;
      cursor: pointer;
      margin-top: 30px;
    }

    &-button-text {
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
    }
  }

  &-section2 {
    padding: 80px 100px 0px 30px;
    &-image {
      border-radius: 30px 0 0 0;
      height: auto;
      max-width: 100%;
    }
  }
}

.faq-container {
  &-section1 {
    padding: 10vw 0 140px 0;
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0,
        #fff 100%
      ),
      url(https://medicalcertificate.in/wp-content/uploads/2024/03/health-clinic-89.jpg) !important;
  }

  &-section2 {
    width: 80%;
    max-width: 1080px;
    margin: auto;
    margin-bottom: 200px;
  }
}

@media only screen and (max-width: 980px) {
  .card-container {
    display: grid;
    grid-template-columns: 1fr;

    &-section1 {
      padding: 50px;
      &-title1 {
        font-size: 40px;
      }

      &-title2 {
        font-size: 18px;
      }
    }

    &-section2 {
      padding: 50px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .card-container {
    &-section1 {
      &-title1 {
        font-size: 28px;
      }
      &-title2 {
        font-size: 16px;
      }
    }
  }
}

@media only screen and (max-width: 330px) {
  .card-container {
    &-section1 {
      padding: 20px;
    }
  }
}
