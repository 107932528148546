.hc-date-picker {
  &-label {
    font-family: Open Sans, Open Sans, sans-serif;
    font-size: 13px;
    line-height: 19px;
    font-weight: 500;
    text-transform: none;
    text-decoration: none;
    color: #7c7c7c;
    padding-bottom: 8px;
  }

  &-wrapper {
    margin-top: 8px;
  }

  .form-error {
    font-family: Open Sans, Open Sans, sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #b00;
    margin-top: 4px;
  }
}
