.get-sick-leave-cert {
  width: 100%;
  // background-image: linear-gradient(90deg, #e5f3fc 0, #e5f3fc 100%) !important;

  &-section-1 {
    padding: 2% 0;
    margin: auto;
    width: 80%;
    max-width: 1080px;

    &-title-wrapper {
      margin-bottom: 4.758%;

      &-text {
        font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
        font-size: 80px;
        color: #070707 !important;
        line-height: 1.1em;
        font-weight: 700;
      }
    }

    &-types,
    &-apply {
      margin-bottom: 4.758%;
    }
  }
}

.type-of-sick-leave-cert {
  width: 100%;

  &-section1 {
    width: 80%;
    margin: auto;
    padding: 2% 0;
    &-text {
      text-align: center;
      font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
      font-weight: 700;
      color: #070707;
      font-size: 30px;
      line-height: 1em;
    }
  }

  %image-title-text {
    color: #070707;
    font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
    font-size: 18px;
  }

  &-section2 {
    &-wrapper {
      width: 80%;
      margin: auto;
      &-section {
        margin-right: 5.5%;
        width: 29.6667%;
        text-align: center;
        float: left;
        padding-top: 70px;
        padding-bottom: 50px;

        &-img-wrapper {
          margin-bottom: 30px;
        }

        &-text {
          @extend %image-title-text;
          font-weight: 700;
          padding-bottom: 1em;
        }

        &:last-child {
          margin-right: 0px !important;
        }
      }

      &::after {
        content: "";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
        width: 0;
      }
    }
  }

  &-section3 {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 980px) {
  .type-of-sick-leave-cert {
    &-section2 {
      &-wrapper {
        &-section {
          width: 100% !important;
          margin-bottom: 30px;
          margin-right: 0px !important;
          padding-top: 0px;
        }
      }
    }
  }
}

.horizontal-line-wrapper {
  width: 80%;
  margin: auto;
  padding: 30px 0;
  display: flex;
  justify-content: center;

  &-line {
    height: 2px;
    background-color: rgba(131, 134, 134, 0.5);
    width: 30%;
  }
}

.get-and-why-cert {
  width: 80%;
  margin: auto;
  padding: 2% 0;

  %get-and-why-cert-title {
    font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
    line-height: 1.7em;
    font-weight: 700;
    color: #070707;
    font-size: 30px;
    margin-bottom: 2.75%;
  }

  %get-and-why-cert-desc {
    font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
    line-height: 1.8em;
    font-weight: 500;
    color: #666;
    font-size: 16px;
    margin-bottom: 51px;
  }

  .get-medical {
    text-align: center;
    &-title {
      @extend %get-and-why-cert-title;
    }
    &-desc {
      @extend %get-and-why-cert-desc;
    }
    &-button-wrapper {
      display: flex;
      justify-content: center;
    }
  }
}
