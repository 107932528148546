.about-intro-container {
  padding: 0;

  .about-intro-section1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "about-us-grid-area convenient-expert-grid-area"
      ". convenient-expert-grid-area";
  }

  .about-intro-section3 {
    display: grid;
    grid-template-columns: 1fr;
    background-image: linear-gradient(
      180deg,
      rgba(11, 88, 43, 0.1) 64%,
      #fff 64%
    );
    padding-top: 50px;
    margin-top: 100px;
  }

  .about-intro-section4 {
    margin-top: 200px;
  }
}

.about-us-grid-area {
  grid-area: about-us-grid-area;
}

.convenient-expert-grid-area {
  grid-area: convenient-expert-grid-area;

  padding: 50px;

  .convenient-expert-title {
    font-weight: 700;
    font-size: 60px;
    line-height: 1.4em;
    color: #0b582b;
  }
  .convenient-expert-desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.8em;
    color: #666;
    margin-top: 20px;
  }
}

@media (max-width: 981px) {
  .about-intro-container {
    .about-intro-section1 {
      display: unset;
      .convenient-expert-grid-area {
        padding: 80px 60px;
      }
    }
  }
}
