.testimonial-card {
  background: #e3e3e3;
  border-radius: 3px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  padding: 30px;

  .review-text {
    color: #000000;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.5em;
  }

  .review-by {
    margin-top: 32px;
    font-weight: 700;
    text-align: center;
    font-size: 14px;
    color: #0b582b;
  }
}
