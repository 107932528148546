.bottom-bar-top-container {
  width: 100%;
  background-color: #e9f2d0;
}
.bottom-bar-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  grid-template-areas: "column1 column2 column3 column4";
  width: 80%;
  margin: auto;
  padding: 2% 0;
}

%global-office-text {
  font-family: Montserrat, Helvetica, Arial, Lucida, sans-serif;
  line-height: 1.7em;
}

%city-text {
  @extend %global-office-text;
  cursor: pointer;
  text-transform: capitalize;
  text-align: left;
  font-weight: 500;
}

.column1 {
  grid-area: column1;

  .app-logo {
    height: 132px;
    width: 132px;
    margin-bottom: 30px;
  }

  .number1-doc-cert,
  .bottom-bar-link-label {
    @extend %global-office-text;
    padding-bottom: 1em;
    text-align: left;
    font-weight: 500;
  }

  .bottom-bar-link-label {
    cursor: pointer;
  }

  .social-media-icons-container {
    display: flex;
    column-gap: 5px;

    .social-media-icon {
      width: 32px;
      height: 32px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.column2 {
  grid-area: column2;

  .supported-cities {
    @extend %city-text;
    padding-bottom: 1em;
  }
}

.column3 {
  grid-area: column3;

  .supported-cities {
    @extend %city-text;
    padding-bottom: 1em;
  }
}

.column4 {
  grid-area: column4;

  .global-office-add-title {
    @extend %global-office-text;
    font-weight: 700;
    padding-bottom: 1em;
  }

  .global-office-address {
    @extend %global-office-text;
    padding-bottom: 1em;
    text-align: left;
    font-weight: 500;
  }
}

@media screen and (max-width: 980px) {
  .bottom-bar-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "column1 column2"
      "column3 column4";
  }

  .column1 {
    .app-logo {
      height: 150px;
      width: 150px;
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 480px) {
  .bottom-bar-container {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      "column1"
      "column2"
      "column3"
      "column4";
  }
}
