%common-privacy-title-text {
  color: #666;
  font-family: "Open Sans", Arial, sans-serif;
  line-height: 1.7em;
  padding-bottom: 1em;
  font-size: 14px;
}

.privacy-container {
  width: 80%;
  margin: auto;
  margin-top: 3%;
  margin-bottom: 200px;
}

.privacy-top-title {
  margin-bottom: 20px;
  color: #2a4368;
  font-size: 30px;
  line-height: 1em;
  font-weight: 500;
  font-family: "Open Sans", Arial, sans-serif;
}

.privacy-title {
  @extend %common-privacy-title-text;
  font-weight: 700;
}

.privacy-desc {
  @extend %common-privacy-title-text;
  font-weight: 500;
}
