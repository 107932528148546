%common-title-text {
  color: #666;
  font-family: "Open Sans", Arial, sans-serif;
  line-height: 1.7em;
  padding-bottom: 1em;
  font-size: 14px;
}

.container {
  width: 80%;
  margin: auto;
  margin-top: 3%;
}

.top-title {
  margin-bottom: 20px;
  color: #2a4368;
  font-size: 30px;
  line-height: 1em;
  font-weight: 500;
  font-family: "Open Sans", Arial, sans-serif;
}

.terms-and-conditions-title {
  @extend %common-title-text;
  font-weight: 700;
}

.terms-and-conditions-desc {
  @extend %common-title-text;
  font-weight: 500;
}
