.testimonials-container {
  width: 100%;
  background-color: #0b582b;
  padding-bottom: 60px;
}

.testimonials-container-section1 {
  background-color: #0b582b;
  &-wrapper {
    width: 80%;
    margin: auto;
    padding: 2% 0;
    &-text {
      text-align: center;
      font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
      font-weight: 700;
      color: #ffffff;
      font-size: 60px;
      line-height: 1em;
    }
  }
}

.testimonials-container-section2 {
  &-wrapper {
    width: 80%;
    margin: auto;

    &-section {
      margin-right: 5.5%;
      width: 29.6667%;
      text-align: center;
      float: left;

      &:last-child {
        margin-right: 0px !important;
      }
    }

    &::after {
      content: "";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0;
      width: 0;
    }
  }
}

@media (max-width: 980px) {
  .testimonials-container-section2 {
    &-wrapper {
      &-section {
        width: 100% !important;
        margin-bottom: 30px;
        margin-right: 0px !important;
        padding-top: 0px;
      }
    }
  }
  .testimonials-container-section1 {
    &-wrapper {
      padding: 60px 0 !important;
      &-text {
        font-size: 35px !important;
      }
    }
  }
}
