.chat-with-expert-wrapper {
  border-radius: 0 0 120px 0;
  overflow: hidden;
  box-shadow: 0 42px 108px -26px rgba(11, 88, 43, 0.38);
  background-image: linear-gradient(
      180deg,
      rgba(11, 88, 43, 0.38) 0,
      rgba(11, 88, 43, 0.38) 100%
    ),
    url(../../assets/images/about-us.jpg);

  background-size: cover;
  background-position: 50%;

  display: grid;
  grid-template-rows: 1fr 1fr;
  width: 90%;
  max-width: 90%;
}

.section1 {
  padding-top: 20vw;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-left: 50px;

  .section1-title {
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    line-height: 1.2em;
    text-transform: capitalize;
  }

  .section1-desc {
    color: #fff;
    line-height: 1.8em;
    font-weight: 700;
    font-size: 16px;
    padding-top: 20px;
  }

  .section-button-wrapper {
    display: inline-block;
    cursor: pointer;
    margin-top: 40px;
  }

  .section-button {
    color: #fff;
    text-transform: uppercase;
    line-height: 1.7em;
    background-color: #0b582b;
    font-weight: 600;
    font-size: 16px;
    border-radius: 5px;
    letter-spacing: 2px;
    padding: 20px 30px;
  }
}

.section2 {
  background-color: #0b582b;
  padding: 100px;

  .section2-title {
    font-size: 60px;
    line-height: 1.5em;
    color: #fff;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .steps-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
    margin-top: 20px;
  }

  .circle-icon {
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.24);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .notes-points {
    flex: 1;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4em;
    color: #fff;
  }
}

@media (min-width: 981px) {
  .chat-with-expert-wrapper {
    grid-template-rows: unset;
    grid-template-columns: 1fr 1fr;
  }

  .section1 {
    .section1-title {
      font-size: 60px;
    }
  }
}
