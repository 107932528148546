.file-upload {
  display: flex;
  flex-direction: column;

  &-upload-container {
    position: relative;
  }

  label {
    font-family: Open Sans, Open Sans, sans-serif;
    font-size: 13px;
    line-height: 19px;
    font-weight: 500;
    text-transform: none;
    text-decoration: none;
    color: #7c7c7c;
    padding-bottom: 8px;
  }

  .form-error {
    font-family: Open Sans, Open Sans, sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #b00;
    margin-top: 4px;
  }

  &-choose {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-size: 16px;
    border: 1px dotted #4a4a4a;
    border-radius: 4px;
    color: #070707;
    font-weight: 400;
    padding: 16px;
  }

  &-text {
    flex-grow: 1;
  }

  &-icon {
    margin-left: 10px;
  }

  &-file-name {
    display: flex;
    align-items: center;
    border: 1px dotted #4a4a4a;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
    padding: 10px;
  }

  &-remove-icon {
    cursor: pointer;
    margin-left: 10px;
  }
}
