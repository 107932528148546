.cert-card-wrapper {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  flex: auto;
  font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
  line-height: 1.7em;
  margin-top: 20px;
  margin-bottom: 30px;
  &-title {
    color: #070707;
    padding-bottom: 10px;
    text-align: center;
    font-size: 20px;
  }
  &-desc {
    color: #666;
    text-align: center;
    font-size: 14px;
  }
}

.button-wrapper {
  //flex-grow: 1;

  //align-self: stretch;
  //margin-top: auto;
}