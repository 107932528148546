.about-us-wrapper {
  background-image: linear-gradient(
      1deg,
      #0b582b 0,
      rgba(11, 88, 43, 0.38) 100%
    ),
    url(../../assets/images/about-us.jpg);
  border-radius: 0 0 150px 0;
  overflow: hidden;
  box-shadow: 0 39px 200px -40px rgba(0, 0, 0, 0.3);
  background-size: cover;
  background-position: 50%;
  padding: 10vw 100px 14vw 150px;
}

.about-us-title {
  font-size: 60px;
  font-weight: 700;
  line-height: 1.2em;
  color: #fff;
  padding-bottom: 10px;
}

.about-us-desc {
  font-size: 20px;
  line-height: 1.5em;
  color: #fff;
  font-weight: 500;
}

.about-us-button-wrapper {
  background-color: #62de9d;
  border-radius: 5px;
  border-width: 5px;
  letter-spacing: 2px;
  padding: 20px 25px;
  display: inline-block;
  cursor: pointer;
  margin-top: 30px;
}

.about-us-button {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
}
