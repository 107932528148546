.global-footprint-wrapper {
  background-image: linear-gradient(
      180deg,
      rgba(11, 88, 43, 0.38) 0,
      rgba(11, 88, 43, 0.38) 100%
    ),
    url(../../assets/images/about-us.jpg);
  border-radius: 120px 0 0 0;
  overflow: hidden;
  background-size: cover;
  background-position: 50%;
  padding: 90px 100px;
}

.global-footprint-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 1.2em;
  color: #fff;
  margin-bottom: 20px;
}

.global-footprint-desc1,
.global-footprint-desc2,
.global-footprint-desc3 {
  line-height: 1.5em;
  color: #fff;
  padding-bottom: 1em;
  font-weight: 500;
  font-size: 16px;
}

.global-footprint-desc3 {
  margin-top: 30px;
}
