.home-container {
  padding: 2% 0;
  width: 80%;
  margin: auto;

  &::after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
    width: 0;
  }
}

.home-container-section-1 {
  margin-right: 5.5%;
  float: left;
  width: 57.8%;

  &-title-wrapper {
    margin-bottom: 4.758%;
    &-text-1 {
      font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
      font-size: 80px;
      color: #070707 !important;
      line-height: 1.1em;
      font-weight: 700;
    }

    &-text-2 {
      font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
      font-size: 80px;
      color: #070707 !important;
      line-height: 1.1em;
      font-weight: 700;
    }
  }

  &-types,
  &-apply {
    margin-bottom: 4.758%;
  }
}

@media (max-width: 980px) {
  .home-container-section-1 {
    &-title-wrapper {
      &-text-1,
      &-text-2 {
        font-size: 44px;
      }
    }

    &-types {
      margin-bottom: 30px;
    }
    width: 100%;
    margin-bottom: 30px;
  }
}

.home-container-section-2 {
  margin-right: 0 !important;
  width: 36.7%;
  float: left;

  &:last-child {
    margin-right: 0px !important;
  }
}

@media (max-width: 980px) {
  .home-container-section-2 {
    width: 100%;
  }
}

.doctor-image-wrapper {
  max-width: 100%;
  height: auto;
}

@media (max-width: 980px) {
  .certificates-container-section2 {
    &-wrapper {
      &-section {
        display: flex;
        //width: 100% !important;
        margin-bottom: 30px;
        margin-right: 0 !important;
        padding-top: 0;
      }
    }
  }
}

.certificates-container-section1 {
  background-color: #ffffff;
  &-wrapper {
    width: 80%;
    margin: auto;
    padding: 2% 0;
    &-text {
      text-align: center;
      font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
      font-weight: 600;
      color: #070707;
      font-size: 60px;
      line-height: 1em;
    }
  }
}

.certificates-container-section2 {
  background-color: #ffffff;
  &-wrapper {
    width: 80%;
    margin: auto;
    &-section {
      display: flex;
      margin-right: 5.5%;
      text-align: center;
      float: left;
      padding-top: 70px;
      padding-bottom: 50px;

      &:last-child {
        margin-right: 0 !important;
      }
    }

    &::after {
      content: "";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0;
      width: 0;
    }
  }
}

.certificates-container {
  width: 100%;
  padding: 50px 0px;
}

.types-wrapper {
  font-family: Open Sans, Arial, sans-serif;
  line-height: 1.8em;
  color: #666;
  font-weight: 500;
  font-size: 16px;
}
