.button-container {
  border-radius: 4px;
  background-color: #0b582b;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  cursor: pointer;
}

.disable {
  background-color: #a1a1a1;
  cursor: default;
}

.title {
  font-size: 15px;
  font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 700;
  padding: 15px 40px;
  text-transform: uppercase;
}
