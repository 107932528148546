body {
  padding-top: 80px;
}

.navbar {
  transition: all .4s ease;
  background: #ffffff;
}

.navbar-normal {
  background-color: #ffffff;
}

.navbar-scrolled {
  background-color: #ffffff;
}

.app-logo {
  padding: 5px;
  transition: all .4s ease;
  vertical-align: middle;
}

.logo-normal {
  height: 80px;
  width: 80px;
}

.logo-scrolled {
  height: 50px;
  width: 50px;
}

%tab {
  text-decoration: none;
  display: block;
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  padding-right: 22px;
  transition: all .4s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}

.in-active-tab {
  @extend %tab;
}

.active-tab {
  @extend %tab;
  color: #0b582b;
  font-weight: bold;
}