.sick-form-wrapper {
  width: 80%;
  margin: auto;

  %input-top-label {
    font-family: Open Sans, Open Sans, sans-serif;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    text-transform: none;
    text-decoration: none;
    color: #222;
    padding-bottom: 8px;
  }

  %helper-text {
    font-family: Open Sans, Open Sans, sans-serif;
    font-size: 13px;
    line-height: 19px;
    font-weight: 500;
    text-transform: none;
    text-decoration: none;
    color: #7c7c7c;
    padding-top: 5px;
  }

  .note-wrapper {
    margin-top: 50px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #fff;

    .note-title {
      @extend %input-top-label;
      margin-top: 20px;
      margin-left: 10px;
    }

    .notes-text {
      @extend %input-top-label;
      font-weight: normal;
      font-size: 12px;
    }
  }

  .address-wrapper {
    margin-top: 40px;
    &-label {
      @extend %input-top-label;
      em {
        color: #ff0000 !important;
      }
    }

    &-helper-text {
      @extend %helper-text;
    }

    &-city-state,
    &-postal-country {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 20px;
    }
  }

  .choose-doc-wrapper,
  .other-details-wrapper,
  .special-format-wrapper,
  .medical-cert-date-wrapper,
  .details-of-medical-problem-wrapper,
  .i-am-seeking-wrapper,
  .name-of-org-wrapper,
  .age-wrapper,
  .care-of-wrapper,
  .gender-wrapper,
  .email-wrapper,
  .phone-wrapper,
  .name-wrapper,
  .file-upload-wrapper {
    margin-top: 40px;
    &-label {
      @extend %input-top-label;
      em {
        color: #ff0000 !important;
      }
    }
    &-helper-text {
      @extend %helper-text;
    }
  }

  .details-of-medical-problem-wrapper,
  .name-of-org-wrapper .age-wrapper {
    &-helper-text {
      padding-left: 5px;
    }
  }

  .care-of-wrapper {
    margin-top: 32px;
  }

  .name-wrapper {
    &-first-last {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 20px;
    }
  }

  .top-space {
    margin-top: 15px;
  }
}

.button-wrapper {
  margin: 50px 0px;
  display: flex;
  justify-content: center;
}

.confirmation-dialog {
  .MuiPaper-root {
    width: 500px;
  }

  .MuiDialogTitle-root {
    text-align: center;
    font-size: 20px;
    font-weight: 750;
    font-family: "Futura Std Open Sans", sans-serif !important;
    color: #070707;
    padding-bottom: 5px;
  }

  .MuiDialogContent-root {
    text-align: center;
  }

  .MuiDialogActions-root {
    justify-content: center;
  }

  .dialog-container {
    color: white;
    overflow-x: hidden;
    &.success {
      background: #0b582b;
    }
    &.fail {
      background: #ff0000;
    }
  }

  .dialog-section1 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dialog-section2 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    padding: 50px 0px;

    .desc {
      color: #070707;
      font-weight: 500;
      font-size: 17px;
      padding: 40px 20px;
      text-align: center;
    }
  }

  .override-button {
    padding: 10px 40px;
    padding: 0 25px;
    border-radius: 50px;
    background-color: white;

    &.success {
      border: 1px solid #0b582b;
      color: #0b582b;
    }

    &.fail {
      border: 1px solid #ff0000;
      color: #ff0000;
    }
  }

  .custom-shape-divider-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);

    &.success {
      fill: #0b582b;
    }

    &.fail {
      fill: #ff0000;
    }
  }

  .custom-shape-divider-top svg {
    position: relative;
    display: block;
  }
}
